<template>
  <v-alert
    v-if="showErrors"
    type="error"
    outlined
    class="text-left core-element-info-message"
  >
    <template v-if="typeof errors === 'string'">
      {{ errors }}
    </template>
    <template v-else-if="Array.isArray(errors)">
      <template v-if="errors.length === 1">
        {{ errors[0] }}
      </template>
      <template v-else>
        <ul>
          <li
            v-for="error in errors"
            :key="error"
          >
            {{ error }}
          </li>
        </ul>
      </template>
    </template>
  </v-alert>
</template>

<script>
export default {
  props: {
    errors: {
      type: [Object, Array, String],
      default: () => {}
    },
    showErrors: {
      type: Boolean,
      required: true
    }
  }
}
</script>
